<template>

  <input
      class="survey-input-xs"
      v-model="inputVal"
      @keypress="onlyForCurrency"
         type="number" placeholder="0.00" required name="price" min="0" value="0" step="any">


</template>

<script>

export default {
  props: ["value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    onlyForCurrency ($event) {
      let pvp = this.value

      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || pvp.indexOf('.') !== -1)) { // 46 is dot
        $event.preventDefault();
      }
      // restrict to 2 decimal places
      if(pvp!=null && pvp.indexOf(".")>-1 && (pvp.split('.')[1].length > 1)){
        $event.preventDefault();
      }
    },
  }


};
</script>

<style>
</style>
