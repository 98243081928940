<template>

  <input
      class="survey-input-xs"
      v-model="inputVal"
    type="number" placeholder="0.00" required value="0" step="any">


</template>

<script>

export default {
  props: ["value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {

  }


};
</script>

<style>
</style>
