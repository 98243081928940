<template>

    <textarea v-model="inputVal" class="" name="w3review"></textarea>

</template>

<script>

export default {
  props: ["value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};

</script>

<style>
</style>
