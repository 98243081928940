<template>

  <div class="d-flex">

    <label style="font-size: 12px" class="mx-1">si</label>
    <input
      class="big-checkbox"
      type="radio"
      :value="true"
      v-model="inputVal"
    />

    <label style="font-size: 12px" class="mx-1">no</label>

    <input
      class="big-checkbox"
      type="radio"
      :value="false"
      v-model="inputVal"
    />


  </div>

</template>

<script>

  export default {
    props: ["value"],
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  };


</script>

<style>

</style>
