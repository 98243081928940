<template>

  <div class="d-flex flex-column">
    <input
            class="survey-input"
            v-model="inputVal"
            type="text"
    />
  </div>

</template>

<script>

export default {
  props: ["value"],
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};

</script>

<style>
</style>
