<template>


  <div style="max-width: 1200px" >


    <div style="max-width: 1200px" >

      <div class="d-flex flex-column">

        <div class="border d-flex justify-content-between mb-2">

          <div>
            <router-link  :to="`/session/${sessionId}/pos/${posId}`">

              <b-button class="mr-1" variant="outline-secondary" size="sm">
                <b-icon icon="arrow-left"></b-icon> volver
              </b-button>

            </router-link>

            <b-button class="mr-1" @click="onSave" variant="success" size="sm">
              <b-icon icon="cloud-upload"></b-icon> guardar
            </b-button>

          </div>


          <span class="trencadis-title" style="text-transform: uppercase">{{survey && survey.name}} </span>

          <img class="brand-image mr-1" v-if="survey && survey.brand.static" :src="getThumbnailUri(survey.brand.static)" alt="" height="30" />


        </div>


        <b-alert v-if="!survey" show variant="info">
          <b-icon  icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
          cargando datos...
        </b-alert>

        <div v-else>

          <div v-if="!survey.components.length">
            <b-alert variant="warning" show="">No hay ninguna encuesta</b-alert>

          </div>

          <div v-bind:key="`component_${k}`" v-for="(component,k) in survey.components" class="component-container border my-2">

            <div class="my-title" v-if="component.name && !component.hidelabel">
              <span style="text-transform: uppercase" class="p-1"><b>{{component.name}}</b></span>
            </div>

            <div v-bind:key="`component_product_${k_prod}`" v-for="(product,k_prod) in (getProducts(component))" class="component-product-container border">

              <span v-if="product" class="product-label px-1 mx-1">{{ product.name}}</span>

              <div class="d-flex flex-wrap">
                <SurveyQuestion
                        :initialValue="getInitialQuestionValue(question, product)"
                        @input="(result) => persistQuestionValue(result, question, product)"
                        class="mr-3"
                        v-bind:key="`component_product_${k_prod}_question_${k_question}`"
                        v-for="(question,k_question) in component.survey_questions"
                        :question="question"
                ></SurveyQuestion>
              </div>




            </div>


          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

  import ApiService from "../../../services/apiService";
  import SurveyQuestion from './question'
  import { mapGetters } from "vuex";
  import {orderByUUID} from "../../../common";
  import _ from 'lodash'
  import {logInfo} from "../../../utils";

  export default {
    name: 'survey',
    components: {SurveyQuestion},
    computed: {
      ...mapGetters('auth',['user']),


    },

    data: function () {
      return {
        results: {},
        posId: null,
        sessionId: null,
        surveyId: null,
        survey: null,
        model: null
      }
    },
    methods: {
      async onSave() {
        const data = Object.values(this.results)
        await ApiService.put(`worksession-survey/${this.model.id}/response`,data)
        logInfo("encuesta guardada")
      },

      _resolveKey(questionId, productId) {
        return  productId ? `${questionId}_${productId}` : `${questionId}`
      },

      getInitialQuestionValue(question, product) {
        const key = this._resolveKey(question.id, product && product.id)
        const val = this.results[key]
        return val ? val.value : null
      },

      calculateScore(question, value) {
        return null
      },

      persistQuestionValue(value, question, product) {
        const key = this._resolveKey(question.id, product && product.id)

        this.results[key] = {
          worksessionSurveyId: this.model.id,
          surveyQuestionId: question.id,
          value,
          score: this.calculateScore(question),
          productId: product ? product.id : null
        }
      },

      getProducts(component) {
        return component.products.length ? component.products: [null]
      },

      getThumbnailUri(image) {
        return ApiService.getThumbnailUri(image)
      },
    },

    beforeDestroy() {
      //saving results
      this.$store.dispatch("surveys/saveResults", {
        sessionId: this.sessionId,
        posId: this.posId,
        surveyId: this.surveyId,
        results: this.results
      });
    },

    async mounted() {
      this.sessionId = this.$route.params.id
      this.surveyId = this.$route.params.surveyId
      this.posId = this.$route.params.posId

      const baseUrl = `/worksession/${this.sessionId}/pos/${this.posId}/survey/${this.surveyId}`
      let resp = await ApiService.get(baseUrl)

      if(!resp.data) { //session must be initialized
        resp = await ApiService.post(`${baseUrl}/init`)
      }
      const self = this
      const serverResults = resp.data.worksession_survey_responses.reduce((prev, response)=>{
        const key = self._resolveKey(response.surveyQuestionId, response.productId)
        prev[key] = response
        return prev
      },{})

      this.model = resp.data
      const survey = resp.data.survey

      //sorting components and questions by uuid list
      survey.components = orderByUUID(survey.survey_components, survey.componentOrder)
      survey.components.forEach(component => component.survey_questions = orderByUUID(component.survey_questions, component.questionOrder))

      this.survey = survey
      const savedResults = this.$store.getters['surveys/getResults'](this.sessionId,this.posId,this.surveyId)

      this.results = _.isEmpty(savedResults) ? serverResults : savedResults
    }

  }

</script>



<style>
  .product-label {
    border: 1px solid green;
    background: #999;
    color: white;
    font-weight: bold
  }



</style>
