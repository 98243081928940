<template>

  <input
          class="big-checkbox"
          type="checkbox"
          v-model="inputVal"
  />


</template>

<script>

  export default {
    props: ["value","label"],
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  };
</script>

<style>
</style>
