<template>


  <div>

    <div class="d-flex flex-column p-1">
      <div class="question-label">
        <label class="px-2" style="font-size: 13px">
          {{question.hideLabel ? '&nbsp;' : question.name}}</label>
      </div>
      <div class="border d-flex justify-content-center">

        <TextInput @input="emit" v-if="typeStr === 'string'" v-model="result"></TextInput>

        <PriceInput @input="emit" v-else-if="typeStr === 'money'" v-model="result"></PriceInput>

        <DecimalInput @input="emit"  v-else-if="typeStr === 'decimal'" v-model="result"></DecimalInput>

        <BooleanInput @input="emit"  v-else-if="typeStr === 'bool'" v-model="result"></BooleanInput>

        <CheckboxInput @input="emit" v-else-if="typeStr === 'checkbox'" v-model="result"></CheckboxInput>

        <NumberInput @input="emit" v-else-if="typeStr === 'number'" v-model="result"></NumberInput>

        <TextAreaInput @input="emit" v-else-if="typeStr === 'longtext'" v-model="result"></TextAreaInput>


        <b-alert v-else class="p-0 m-0" variant="danger" show>not implemented</b-alert>

      </div>

    </div>

  </div>

</template>

<script>

  import ApiService from "../../../services/apiService";
  import { mapGetters } from "vuex";
  import TextInput from './inputs/Text'
  import DecimalInput from './inputs/Decimal'
  import BooleanInput from './inputs/Boolean'
  import CheckboxInput from './inputs/Checkbox'
  import NumberInput from './inputs/Number'
  import PriceInput from './inputs/Price'
  import TextAreaInput from './inputs/TextArea'

  export default {
    name: 'surveyQuestion',
    props: ['question','initialValue'],
    components: { TextInput, CheckboxInput, PriceInput, NumberInput, DecimalInput, TextAreaInput, BooleanInput },
    watch: {},

    computed: {
      ...mapGetters('auth',['user']),
      strValue() {
        try{
          return this.result.toString()
        }catch (e) {
          return this.result
        }
      }
    },

    data: function () {
      return {
        result: null,
        type: null,
        typeStr: null
      }
    },
    methods: {

      emit() {
        this.$emit('input', this.strValue);
      },

      getThumbnailUri(image) {
        return ApiService.getThumbnailUri(image)
      },
    },

    async mounted() {
      this.type = this.question.type
      this.typeStr = this.question.survey_question_type.type
      this.result = this.initialValue
    }

  }

</script>



<style>



</style>
